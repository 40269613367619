import React from "react";
import { Typography, alpha } from "@mui/material";
import { styled } from "@mui/material/styles";

const Heading: React.FC<{ text: string }> = (props) => {
  const AuthHeading = styled(Typography)(({ theme }) => ({
    fontWeight: 300,
    marginBottom: 8,
    fontSize: "3.8rem",
    color: theme.palette.primary.dark,
  }));

  return <AuthHeading variant="h1">{props.text}</AuthHeading>;
};

export default Heading;
