import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { useOktaAuth } from '@okta/okta-react';
import { useNavigate } from "react-router-dom";
import Heading from "../components/Heading";
import AuthHeaderComponent from "../components/AuthHeaderComponent";
import AuthContentComponent from "../components/AuthContentComponent";
import commons from "../Utils/constants";
import { text } from "stream/consumers";


const BoxStyled = styled(Box)(({ theme }) => ({
    color: theme.palette.primary.main,
    width: "100%",
    height: "100%",
}));


type Props = {
    children?: React.ReactNode;
    text: string
  };

const Layout: React.FC<Props>  = (props) => {
  
  return (
    <>
    <AuthHeaderComponent text={props.text} />
      <AuthContentComponent>
        <BoxStyled className="auth-component">
        {props.children}
        </BoxStyled>
      </AuthContentComponent>
    </>
  );
};

export default Layout;
