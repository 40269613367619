import React from "react";
import { Typography, alpha } from "@mui/material";
import { styled } from "@mui/material/styles";

const Asterisk: React.FC = () => {
  const AsteriskStyled = styled("label")(({ theme }) => ({
    //Change all of these to variables
    color: "#b94a48",
    marginLeft: 5,
    verticalAlign: "middle",
    fontSize: "1.2em",
  }));

  return <AsteriskStyled>* </AsteriskStyled>;
};

export default Asterisk;
