
import React, { FunctionComponent, useEffect, useState } from 'react';
//import logo from './logo.svg';
import './App.css';
import Login from './Pages/CeLogin';
import {
  ThemeProvider,
  createTheme,
  Theme,
  styled,
} from "@mui/material/styles";
import OktaWithRouterAccess from "./components/OktaConfig";
import CeLogin from "./Pages/CeLogin";
import SignUp from "./Pages/SignUp";
import PeSignUp from "./Pages/PESignup";

import { Grid } from "@mui/material";
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import PeLogin from './Pages/PeLogin';
import { Route, Routes  } from 'react-router-dom';
import ProducerEngage from './Pages/ProducerEngage'
import CustomerEngage from './Pages/CustomerEngage';
import useAnalyticsEventTracker from './components/GA4Tracker/index'
import  Home from "./Pages/Home/Home";
import ReactGA from 'react-ga4';
import common from './config';

import ForgotPasswordConfirmation from './Pages/ForgotPassword/ForgotPWConfirmation';
import NoMatch from './components/NoMatch';

import LogoutSession from './components/LogoutSession';

import Logout from './Pages/Logout/Logout';
import SignupConfirmation from './components/SignupConfirmation';
import ResetPasswordConfirmation from './Pages/ResetPassword/resetPassConfirmation'

const config = common.config();
/// add config config.gaTracking prod or qa

let options = {};

const TRACKING_ID = config.ga4Tracker.GA_MEASUREMENT_ID; // YOUR_OWN_TRACKING_ID
//console.log("🚀 ~ file: App.tsx ~ line 45 ~ config", config);

//console.log(' process.env',process.env);//
if(config?.ga4Tracker.testMode!== undefined){
   options = {
    testMode: config.ga4Tracker.testMode as boolean
  }
//  console.log(options)
  ReactGA.initialize(TRACKING_ID, options);
 // console.log("🚀 ~ file: App.tsx ~ line 57 ~ TRACKING_ID", TRACKING_ID)


}else{
  ReactGA.initialize(TRACKING_ID);
 // console.log("🚀 ~ file: App.tsx ~ line 62 ~ TRACKING_ID", TRACKING_ID)

}
// console.log("🚀 ~ file: App.tsx ~ line 50 ~ options", options);

//ReactGA.pageview(window.location.pathname + window.location.search);
declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    phonePortrait: true;
    phoneLandscape: true;
    tabletPortrait: true;
    tabletLandscape: true;
    desktopSmall: true;
  }
}

const theme: Theme = createTheme({
  components:{
    MuiTextField: {
      defaultProps:{

      },
      styleOverrides:{
        root:{
         // height:'40px'
        }
      }
    }
  },
  palette: {
    primary: {
      main: "#003057",
      dark: "#000000",
      light: "#fff",
    },
    success: {
      main: "#c1531b",
    },
  },
  typography: {
    // fontFamily: "Graphik Web",
    fontSize: 14,
    fontWeightMedium:'normal',
    fontFamily: '"Graphik Web", "Libre Franklin", sans-serif, monospace',
    allVariants:{
      color: '#373737'
    },
    body1:{
      color:'#373737',
      fontSize: '1.2rem',
      //marginBottom: '10px'
    }
  },
  breakpoints: {
    values: {
      phonePortrait: 480,
      phoneLandscape: 640,
      tabletPortrait: 768,
      tabletLandscape: 1024,
      desktopSmall: 1200,
    },
  },
  spacing: 8,
});

const AppBox = styled(Grid)(({ theme }) => ({
  height: "100% !important",
  width: "100vw",
  [theme.breakpoints.between(1, 641)]: {
    display: "box",
  },
  [theme.breakpoints.up(641)]: {
    display: "flex",
  },
}));

const App: FunctionComponent = () => {

   ReactGA.send("pageview");
   //usePageTracking();
   const [deeplink, setDeeplink] = useState("");
   const [isFromURI, setIsFromURI] = useState(false);
   const queryParameters = new URLSearchParams(window.location.search);

   const checkFromURI = () => {

    const fromURI: string | null = queryParameters.get("fromURI");
    //console.log("fromURI in FormSubmission : ", fromURI);
    
   // console.log("🚀 ~ file: FormSubmission.tsx ~ line 149 ~ .then ~ fromURI : ", fromURI);
    if(fromURI != undefined  && fromURI?.length > 4){
     // console.log("🚀 ~ file: FormSubmission.tsx ~ line 151 ~ fromURI exists and >4 in length: ",  fromURI);
      setIsFromURI(true);
      setDeeplink(fromURI);
     // console.log("🚀 ~ file: FormSubmission.tsx ~ line 154 ~ fromURI setDeeplink");
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <OktaWithRouterAccess>
        <AppBox className="App">
            <Routes>
              <Route path="/" element={<Home checkFromURI={checkFromURI} isFromURI={isFromURI} deeplink={deeplink}/>} />
              <Route path="producer-engage" element={<ProducerEngage />} >
                <Route path="login" element={<PeLogin />} />
                <Route path="signup" element={<PeSignUp />} />
                <Route path="signup/confirmation" element={<SignupConfirmation />} />
                <Route path="forgotpassword" element={<ForgotPassword />} />
                <Route path="forgotpassword/confirmation" element={<ForgotPasswordConfirmation />} />
                <Route path="resetPassword/confirmation" element={<ResetPasswordConfirmation />} />
                {/*
                  <Route path="resetPassword" element={<ResetPassword />}>
                    <Route path=":token" element={<div />} />
                  </Route> 
                */}
                <Route path="resetPassword" element={<ResetPassword />} />
                <Route path="logout" element={<Logout/>} />
              </Route>
              <Route path="customer-engage" element={<CustomerEngage />} >
                <Route path="login" element={<CeLogin />} />
                <Route path="forgotpassword" element={<ForgotPassword />} />
                <Route path="forgotpassword/confirmation" element={<ForgotPasswordConfirmation />} />
                {/* <Route path="resetPassword" element={<ResetPassword />}>
                  <Route path=":token" element={<div />} />
                </Route> */}
                <Route path="logout" element={<Logout />} />
                <Route path="signup" element={<SignUp />} />
                <Route path="signup/confirmation" element={<SignupConfirmation />} />
                <Route path="logout" element={<Logout/>} />
                <Route path="resetPassword" element={<ResetPassword />} />
                <Route path="resetPassword/confirmation" element={<ResetPasswordConfirmation />} />
              </Route>
              <Route path="*" element={<NoMatch />} />
            </Routes>
          </AppBox>
      </OktaWithRouterAccess>
    </ThemeProvider>
  );
}

export default App;
