// First we need to import axios.js
import common from './config';
import axios, {
    AxiosRequestConfig,
    AxiosResponse,
    AxiosError,
    AxiosInstance,
    AxiosAdapter,
    Cancel,
    CancelToken,
    CancelTokenSource,
    Canceler
  } from 'axios';
  
// Next we make an 'instance' of it
const config = common.config();
const axiosInstance = axios.create({
// .. where we make our configurations
    baseURL: config.baseUrl
});

// Where you would set stuff like your 'Authorization' header, etc ...
//axiosInstance.defaults.headers.common['Authorization'] = 'AUTH TOKEN FROM INSTANCE';

// Also add/ configure interceptors && all the other cool stuff

    // Not needed for this example
// instance.interceptors.request.use(
//     (config: AxiosRequestConfig) => Promise.resolve(config),
//     (error: any) => Promise.reject(error)
//   );

export default axiosInstance;