import React from "react";
import {
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { styled } from "@mui/material/styles";

const PasswordField: React.FC<{
  showPassword: boolean;
  passWordHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  passwordVisibilityHandler: () => void;
}> = (props) => {
  
  return (
    <TextField
      margin="normal"
      required
      fullWidth
      name="password"
      label="Password"
      type={props.showPassword ? "text" : "password"}
      id="password"
      // autoComplete="current-password"
      onChange={props.passWordHandler}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={props.passwordVisibilityHandler}
            >
              {props.showPassword ? (
                <FontAwesomeIcon icon={faEye} />
              ) : (
                <FontAwesomeIcon icon={faEyeSlash} />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
