import React, { useCallback, useState } from "react";
import Box from "@mui/material/Box";
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Heading from "../components/Heading";
import AuthHeaderComponent from "../components/AuthHeaderComponent";
import AuthContentComponent from "../components/AuthContentComponent";
import PasswordField from "../components/PasswordField";
import FormSignUp from "../components/FormSignUp";
import Layout from "./Layout";
import Note from "../components/Note";
import config from '../config';


const SignUp: React.FC = (props) => {
  const BoxStyled = styled(Box)(({ theme }) => ({
    color: theme.palette.primary.main,
    width: "100%",
    height: "100%",
  }));

  return (
    <>
      <Note/>
      <Heading text={"Sign Up"} />
      <FormSignUp />
    </>
  );
};

export default SignUp;
