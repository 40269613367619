import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Heading from "../components/Heading";
import StylizedLink from "../components/StylizedLink";
import FormSubmission from "../components/FormSubmission";
import HasError from "../components/HasError";
import AuthHeaderComponent from "../components/AuthHeaderComponent";
import AuthContentComponent from "../components/AuthContentComponent";
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography/Typography";
import TermsAndConditions from "../components/TermsandConditions";
import Layout from "./Layout";
import { useOktaAuth } from "@okta/okta-react";
import { Route, Routes, Outlet  } from 'react-router-dom';

import { useSearchParams } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});




export default function ProducerEngage(props:any) {
  const [showTermsConditions, setShowTermsConditions] = useState(false);
  const { oktaAuth } = useOktaAuth();

  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    document.title = 'ProducerEngage'
  },[])


  useEffect(()=>{
      console.log('searchParams', searchParams.get("action") || "")
    if(searchParams.get("action") === "logout"){
      logout();
    }
  },[searchParams])


  const logout = async () => {
		// await oktaAuth.closeSession();
   	await oktaAuth.signOut({
			clearTokensBeforeRedirect: true,
			revokeAccessToken:true,
			revokeRefreshToken:true,
		});
  };
//need function to clear any sessions that might be present


  const handleClose = () => {
    setShowTermsConditions(false);
  };
  const handleClickOpen = () => {
    setShowTermsConditions(true);
  };
  
  const BottomText = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(3),
    display: "block",
  }));

  return (
    <>
      <Layout text={"ProducerEngage"}>
   
        {/* {props.children}asdfasdf */}
            <Outlet />
      </Layout>
  </>
  );
}