import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Asterisk from "../../components/Asterisk";
import Note from "../../components/Note";
import StylizedLink from "../../components/StylizedLink";
import HasError from "../../components/HasError";
import { useOktaAuth } from '@okta/okta-react';
import AuthHeaderComponent from "../../components/AuthHeaderComponent";
import AuthContentComponent from "../../components/AuthContentComponent";
import { useNavigate } from "react-router-dom";
import Layout from "../Layout";
import Heading from "../../components/Heading";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { useLocation } from 'react-router-dom';

import common from '../../config';

const config = common.config();

const BoxStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: 6,
  marginRight: 0,
  marginBottom: 45,
  marginLeft: 0,
}));

const ForgotPasswordConfirmation: React.FC = (props) => {
  // let location = useLocation();
  const navigate = useNavigate();

  const [backtoSignIn, setBacktoSignIn] = useState<undefined | string>();

  let locationHook = useLocation();
  let location = locationHook.pathname;
 // console.log('{location: FormSubmission}', location);

  let isPE = location.match('/producer-engage/forgotpassword/confirmation');
  let category = isPE ? 'Producer_Engage' : 'Customer_Engage';
  
  
  useEffect(() => {
    if(category == "Producer_Engage") {
      setBacktoSignIn(`/producer-engage/login`);
    } else {
      setBacktoSignIn(`/customer-engage/login`);
    }
    // if(backtoSignIn) {
    //   setTimeout(()=>{
    //     navigate(`${backtoSignIn}`, { replace: true })
    //   }, 5000)
    // }
  }, [backtoSignIn]);

  return(
    <>
      <Grid container sx={{
        position: 'relative',
        top: '20%'
      }}>
        <Grid item alignContent={"space-evenly"}>
          <Typography variant="h2">
            {`Check your email`}
          </Typography>
        </Grid>
        <Grid container>
        <Typography variant="body1">
          {`A password reset link has been sent. Click the link in the email message to reset your password.`}
        </Typography>
        <StylizedLink
          text={"Back to Sign In"}
          value={`${backtoSignIn}`} 
          />
          
        </Grid>
      </Grid>
    </>
  )

};


export default ForgotPasswordConfirmation;
