import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  List,
  ListItem,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link as BrowserLink, useLocation, useNavigate } from "react-router-dom";
import ErrorModal from "../Pages/ErrorPages/ErrorModal";
import HasError from "./HasError";
import PasswordField from "./PasswordField";
import commons from "../Utils/constants";
import UiPasswordValidation from "./UiPasswordValidation";
import ComponentLink from "./ComponentLink";
import axiosInstance from "../axiosConfig";
import ReactGA from 'react-ga4';
import useAnalyticsEventTracker from '../components/GA4Tracker/index';


const ButtonStyled = styled(Button)(({ theme }) => ({
  boxShadow: "none",
  textTransform: "none",
  fontWeight: "bold",
  outline: 0,
  position: "relative",
  padding: "1rem 15px",
  border: "none",
  display: "inline-block",
  lineHeight: 1.5,
  backgroundColor: theme.palette.success.main,
  color: theme.palette.primary.light,
  fontSize: "1.5rem",
  minWidth: 152,
  minHeight: 40,
  margin: 0,
  textAlign: "center",
  transition: "none",
  [theme.breakpoints.down(768)]: {
    minWidth: 80,
  },
  "&:hover": {
    backgroundColor: theme.palette.success.main,
  },
  "&:disabled": {
    color: "#fff",
    backgroundColor: "#ddd",
  },
}));



const SignupConfirmation = () => {
  return (
    <div>
    
      <Box component="form" noValidate >

     
      </Box>
      <Divider />
      <Typography sx={{ marginTop: 2 }} variant="h5">
      {`Sign up Complete you will be redirected back to login page shortly...`}
      </Typography>
      <ComponentLink text={"if you are not automatically redirected click here"} value={"/customer-engage/login"} />

    </div>
  );
};

// do not touch 

const FormSignUp: React.FC = (props) => {
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [firstname, setFirstName] = useState("");

  const [lastname, setLastName] = useState("");
  const [accountnumber, setAccountNumber] = useState("");
  const [policynumber, setPolicyNumber] = useState("");
  const [errorValue, setErrorValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [disableButton, setDisableButton] = useState(true);

  
  let locationHook = useLocation();
  let location = locationHook.pathname;
  
  let isPE = location.match('/producer-engage/forgotpassword') ;
  let category = isPE ? 'Producer_Engage' : 'Customer_Engage';
  const environmentGA =  process.env.REACT_APP_ENV;
  console.log('{category}', category);
  var gaEventTracker = useAnalyticsEventTracker(category);

  const {
    uppercaseRegExp,
    lowercaseRegExp,
    numbersRegExp,
    specialCharRegExp,
    emailRegexp,
  } = commons;

  const [apiError, setApiError] = useState<any>("");
  const [modalHeader, setModalHeader] = useState("");

  const [openApiErrorModal, setOpenApiErrorModal] = useState(false);

  useEffect(() => {
    if (
      firstname.length > 0 &&
      lastname.length > 0 &&
      accountnumber.length > 0 &&
      policynumber.length > 0 &&
      emailRegexp.test(email) &&
      password.length >= 8 &&
      lowercaseRegExp.test(password) &&
      numbersRegExp.test(password) &&
      uppercaseRegExp.test(password) &&
      specialCharRegExp.test(password)
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [firstname, lastname, email, password, accountnumber, policynumber]);

  useEffect(() => {
    if (!emailRegexp.test(email) && email.length > 1) {
      setErrorValue("Please enter a valid email address");
    } else {
      setErrorValue("");
    }
  }, [email]);

  const firstNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };

  const lastNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const emailHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const accountNumberHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccountNumber(event.target.value);
  };

  const policyNumberHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPolicyNumber(event.target.value);
  };

  const passWordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const passwordVisibilityHandler = () => {
    setShowPassword((prev) => !prev);
  };

  const handleErrorModalAccountExists = () => {
    setOpenApiErrorModal(false);
    setApiError("");
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let payload = {
      firstName: firstname,
      lastName: lastname,
      email: email.toLowerCase(),
      password: password,
      accountNumber: accountnumber,
      policyNumber: policynumber.toUpperCase(),
    };
    try {
      console.log("Axios Instance ",axiosInstance.getUri());
      let res = await axiosInstance.post("/signup", { payload });
      console.log("🚀 ~ file: FormSignUp.tsx ~ line 184 ~ handleSubmit ~ axiosInstance", axiosInstance)
      console.log("🚀 ~ file: FormSignUp.tsx ~ line 184 ~ handleSubmit ~ res", res);
      
      gaEventTracker('Signup_Success', environmentGA);
      navigate("confirmation");
    } catch (e: any) {
      if (e.response.data.error.toLowerCase() === "sign up criteria errors") {
        console.log("🚀 ~ file: FormSignUp.tsx:198 ~ handleSubmit ~ e.response.data.error", e.response.data.error)
        const errorCauses = e.response.data.errorCause;
        var dataElement = errorCauses[0];
        if (dataElement.toLowerCase() === "information not valid") {
          setApiError(
            `The information entered is not valid. If you need further assistance contact 800.231.1363.`
          );
          setModalHeader("Error")
          setOpenApiErrorModal(true);
        } else if (dataElement.toLowerCase() === "admin exists") {
          setApiError(
            `This account has been registered already with the CopperPoint system. To receive access, please speak with your local administrator or call 800.231.1363 for assistance`
          );
          setModalHeader("Account already registered with assigned admin")
          setOpenApiErrorModal(true);
        } else if (dataElement.toLowerCase() === "account exists") {
          setApiError(
            <p>
              Please select <em>Forgot your password?</em> at the Sign In page, or call 1.800.231.1363 for further assistance.
              
            </p>
          );
          setModalHeader("Email already exists")
          setOpenApiErrorModal(true);
        }
      } else {
        setApiError(`Error`);
        setOpenApiErrorModal(true);
      }
      gaEventTracker('Signup_Fail', environmentGA);

      // ReactGA.event({
      //   category: category,
      //   action: "Signup_Fail",
      //   label: category
      //   //value: 99, // optional, must be a number
      // });
      console.log("The error is ", e);
    }finally{
    //  navigate("confirmation");
    }
  };

  const ListHeading = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(2),
    fontWeight: "bold",
    color: "#848484",
  }));

  const ListStyled = styled(List)(({ theme }) => ({
    listStyleType: "disc",
    padding: 0,
  }));

  const ListItemStyled = styled(ListItem)(({ theme }) => ({
    display: "list-item",
    marginLeft: theme.spacing(2),
    paddingBottom: 0,
    fontWeight: "bold",
    color: "#848484",
  }));

  const ButtonWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "6px 0 45px",
    marginBottom: theme.spacing(1),
  }));

  return (
    <div>

      <>
        <HasError text={errorValue} />
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="firstname"
            label="First Name"
            name="firstName"
            autoComplete="First Name"
            autoFocus
            onChange={firstNameHandler}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="lastname"
            label="Last Name"
            name="lastname"
            autoComplete="Last Name"
            onChange={lastNameHandler}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="Email"
            value={email}
            autoFocus
            onChange={emailHandler}
          />
          <PasswordField
            showPassword={showPassword}
            passWordHandler={passWordHandler}
            passwordVisibilityHandler={passwordVisibilityHandler}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="accountnumber"
            label="Account Number"
            name="accountnumber"
            autoComplete="Account Number"
            value={accountnumber}
            autoFocus
            onChange={accountNumberHandler}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="policynumber"
            label="Policy Number"
            name="policynumber"
            autoComplete="Policy Number"
            value={ policynumber.toUpperCase()}
            autoFocus
            onChange={policyNumberHandler}
          />
          <ButtonWrapper>
            <ButtonStyled
              type="submit"
              disabled={disableButton}
              sx={{ marginBottom: 2, marginTop: 1 }}
            >
              Sign Up
            </ButtonStyled>
          </ButtonWrapper>
        </Box>
        <Divider />
        <Typography sx={{ marginTop: 2 }} variant="h5">
          Already have a login?
        </Typography>
        <ComponentLink text={"Sign In"} value={"/customer-engage/login"} />
        
        <UiPasswordValidation password={password} />

        <ErrorModal
          header={modalHeader}
          open={openApiErrorModal}
          handleErrorModal={handleErrorModalAccountExists}
          error={apiError}
        />
      </>
    
    </div>
  );
};

export default FormSignUp;
