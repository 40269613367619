import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  List,
  ListItem,
  Divider,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link as BrowserLink, useLocation, useNavigate } from "react-router-dom";
import ErrorModal from "../Pages/ErrorPages/ErrorModal";
import HasError from "./HasError";
import PasswordField from "./PasswordField";
import commons from "../Utils/constants";
import UiPasswordValidation from "./UiPasswordValidation";
import ComponentLink from "./ComponentLink";
import axiosInstance from "../axiosConfig";
import ReactGA from 'react-ga4';
import useAnalyticsEventTracker from '../components/GA4Tracker/index';
import common from '../config';
import StylizedLink from "../components/StylizedLink";


const config = common.config();


const SignupConfirmation: React.FC = () => {
    const navigate = useNavigate();
    const [backtoSignIn, setBacktoSignIn] = useState<undefined | string>();

    let locationHook = useLocation();
    let location = locationHook.pathname;
    console.log('{location: FormSubmission}', location);
  
    let isPE = location.match('/producer-engage/signup/confirmation');
    let category = isPE ? 'Producer_Engage' : 'Customer_Engage';
    useEffect(() => {
      if(category == "Producer_Engage") {
        setBacktoSignIn(`/producer-engage/login`);
      } else {
        setBacktoSignIn(`/customer-engage/login`);
      }
      // if(backtoSignIn) {
      //   setTimeout(()=>{
      //     navigate(`${backtoSignIn}`, { replace: true })
      //   }, 5000)
      // }
    }, [backtoSignIn]);


    return (
      
      <Grid container sx={{
        position: 'relative',
        top: '25%'
      }}>
        <Grid item alignContent={"space-evenly"}>
          <Typography sx={{ marginTop: 2,
            font: '2.5rem',
            marginBottom:'19px'
          }} variant="h4">
            {`Thank you for registering.`}
      
          </Typography>
        </Grid>
      
         
       <Grid 
          container
          sx={{
            width:'80%',
            marginLeft: '10px'
          }}
        >
          <StylizedLink
          text={"Back to Sign In"}
          value={`${backtoSignIn}`} 
          />
        </Grid> 
      </Grid>
    );
  };
  
  // do not touch 


  export default SignupConfirmation;