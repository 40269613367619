import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  TextField,
  Grid,
  List,
  ListItem,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Asterisk from "../../components/Asterisk";
import Note from "../../components/Note";
import StylizedLink from "../../components/StylizedLink";
import HasError from "../../components/HasError";
import PasswordField from "../../components/PasswordField";
import { useOktaAuth } from "@okta/okta-react";
import commons from '../../Utils/constants';
import UiPasswordValidation from "../../components/UiPasswordValidation";
import axiosInstance from "../../axiosConfig";
import common from '../../config';
import ReactGA from 'react-ga4';
import useAnalyticsEventTracker from '../../components/GA4Tracker/index';

const config = common.config();

const BoxStyled = styled(Box)(({theme }) => ({
  color: theme.palette.primary.main,
  width: "100%",
  height: "100%",
}));

const BottomText = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: "block",
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  boxShadow: "none",
  textTransform: "none",
  fontWeight: "bold",
  outline: 0,
  position: "relative",
  padding: "1rem 15px",
  border: "none",
  display: "inline-block",
  lineHeight: 1.5,
  backgroundColor: theme.palette.success.main,
  color: theme.palette.primary.light,
  fontSize: "1.5rem",
  minWidth: 152,
  minHeight: 40,
  margin: 0,
  textAlign: "center",
  transition: "none",
  [theme.breakpoints.down(768)]: {
    minWidth: 80,
  },
  "&:hover": {
    backgroundColor: theme.palette.success.main,
  },
  "&:disabled": {
    color: "#fff",
    backgroundColor: "#ddd",
  },
}));

const ForgotPasswordForm: React.FC = (props) => {
  const [password, setPassword] = useState("");
  const [errorValue, setErrorValue] = useState("");
  const[error, setError] = useState("");
  const navigate = useNavigate();
  const [email, setEmail] = useState(""); 
   const [disableButton, setDisableButton] = useState(true);
  const {uppercaseRegExp, lowercaseRegExp, numbersRegExp, specialCharRegExp, emailRegexp } = commons;
  let locationHook = useLocation();

  let location = locationHook.pathname;
  let isPE = location.match('/producer-engage/forgotpassword') 
  const environmentGA =  process.env.REACT_APP_ENV;
  
  let category = isPE ? 'Producer_Engage' : 'Customer_Engage';
 var gaEventTracker = useAnalyticsEventTracker(category);
  let backtoSignIn;
  if(category == "Producer_Engage") {
    backtoSignIn = `${config.baseUrl}/producer-engage/login`;
  } else {
    backtoSignIn = `${config.baseUrl}/customer-engage/login`;
  }
  
  useEffect(() => {
    if (
      emailRegexp.test(email) &&
      email.length > 10
    ) {
      setDisableButton(false);
    }{
      // setDisableButton(true);
      // setError("Please enter a valid email address");
    }
  }, [email]);

  const emailHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const errorHandler  =(e: React.FocusEvent<HTMLInputElement>) =>{
      if ( !emailRegexp.test(email) || !(email.length > 10)) {
        setErrorValue("Please enter a valid email address");
    } else {
      setErrorValue("");
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  
    let newPasswordEntryUrl;

    if(isPE) {
      newPasswordEntryUrl = `${config.baseUrl}/producer-engage/resetpassword`;
    } else {
      newPasswordEntryUrl = `${config.baseUrl}/customer-engage/resetpassword`;
    }
    
    event.preventDefault();

    // let email = event.target
    let payload = { 
      applicationName: isPE ? "PE" : "CE",
      email: email,
      newPasswordEntryUrl: newPasswordEntryUrl //change base on Environment
    };
    
    try {
      console.log(axiosInstance);
      let res =  await axiosInstance.post('/forgotPassword', payload);

    if(res.status==200){
        gaEventTracker('Forgot_Password_Success', environmentGA);
        console.log(res);
    }
    navigate({pathname: `confirmation`}, {replace: true});
    
    } catch (e: any) {
    
      gaEventTracker('Forgot_Password_Fail', environmentGA);
      setErrorValue("The information entered is not valid. If you need further assistance contact:800.231.1363");
      console.log('error', e)
    } finally{
  

    }
  };
  return (
    <>
      <HasError text={errorValue} />
      <Box sx={{
        minWidth:"350px"
      }} component="form" noValidate onSubmit={handleSubmit}>
      <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          autoFocus
          onBlur={errorHandler}
          //onFocus={onFocus}
          onChange={emailHandler}
        />
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <StylizedLink text={"Back To Sign In"} value={`${backtoSignIn}`} goBack={true} />
          <ButtonStyled
            type="submit"
            disabled={disableButton}
            sx={{ marginBottom: 4, marginTop: 4 }}
          >
            Send
          </ButtonStyled>
        </Grid>
      </Box>
      <Divider />
    </>
  );
};

export default ForgotPasswordForm;
