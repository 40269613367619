import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { styled } from "@mui/material/styles";
import { Divider, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import Heading from "../components/Heading";
import StylizedLink from "../components/StylizedLink";
import FormSubmission from "../components/FormSubmission";
import HasError from "../components/HasError";
import AuthHeaderComponent from "../components/AuthHeaderComponent";
import AuthContentComponent from "../components/AuthContentComponent";
import Layout from "./Layout";
import ComponentLink from "../components/ComponentLink";
import { useOktaAuth } from "@okta/okta-react";

//const config = common.config();

export default function PeLogin() {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  let navigate = useNavigate();
  const { oktaAuth } = useOktaAuth();

  let [searchParams, setSearchParams] = useSearchParams();




  useEffect(()=>{
  //  console.log('searchParams', searchParams.get("action") || "")
    if(searchParams.get("action") === "logout"){
        logout();
    }
  },[searchParams])



  const BottomText = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(3),
    display: "block",
  }));

  const logout = async () => {
		// await oktaAuth.closeSession();
   	await oktaAuth.signOut({
			clearTokensBeforeRedirect: true,
			revokeAccessToken:true,
			revokeRefreshToken:true,
		});
  };

  return (
    <>
      <Heading text={"Policyholder Login"} />
        <FormSubmission />
        <Divider />
        <Typography sx={{ mt: "24px" }} variant="body1">
          Don't have a login? <ComponentLink text={"Sign Up"} value={"/customer-engage/signup"}/>
        </Typography>
        <BottomText>
          <StylizedLink
            text={"Legal & Privacy"}
            target="_blank"
            value={"https://www.copperpoint.com/legal-privacy"}
            />
        </BottomText>

    </>
  );
}
