import React from "react";
import ReactGA from 'react-ga4';

const useAnalyticsEventTracker = (category="Blog category") => {
  const eventTracker = (action = "test action", label = "test label") => {
    ReactGA.event({category, action, label});
  }
  return eventTracker;
}
export default useAnalyticsEventTracker;

// ReactGA.event({
//   category: "your category",
//   action: "your action",
//   label: "your label", // optional prod
//   value: 99, // optional, must be a number
//   nonInteraction: true, // optional, true/false
//   transport: "xhr", // optional, beacon/xhr/image
// });