import React from "react";
import { styled } from "@mui/material/styles";
import Asterisk from "./Asterisk";
import { red } from "@mui/material/colors";

const Note: React.FC = () => {
  const DivStyled = styled("div")(({ theme }) => ({
    color: "#b94a48",
    marginTop: theme.spacing(2),
    paddingBottom: 25,
    fontSize: '13px'
  }));

  return (
    <div>
      <br />
      <DivStyled> If your organization has already registered, an individual cannot sign up from this page. Please ask your administrator to register your email address from the User Admin page. If you do not know your organization’s admin, you may contact us at 800.231.1363. </DivStyled>
    </div>
  );
};

export default Note;
