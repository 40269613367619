import React, { useState } from "react";
import {
  Typography,
  List,
  ListItem,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Asterisk from "./Asterisk";
import Note from "./Note";
import StylizedLink from "./StylizedLink";
import HasError from "./HasError";
import { useOktaAuth } from '@okta/okta-react';
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import commons from "../Utils/constants";

const UiPasswordValidation: React.FC<{password:string}> = ({password}) => {

    const {uppercaseRegExp, lowercaseRegExp, numbersRegExp, specialCharRegExp, emailRegexp } = commons;

    const ListHeading = styled(Typography)(({theme}) => ({
        marginTop: theme.spacing(2),
        fontWeight: "bold",
        color: "#848484"
      }))
    
      const ListStyled = styled(List)(({theme}) => ({
        listStyleType: "disc",
        padding: 0
      }))
    
      const ListItemStyled = styled(ListItem)(({theme}) => ({
        display: "list-item",
        marginLeft: theme.spacing(2),
        paddingBottom: 0,
        fontWeight: "bold",
        color: "#848484",
      }))
 // const nav = useNavigate();
//console.log('navigate(-1)', nav);
  return (
    <>
      <Grid>
        {/* validation */}
        <ListHeading variant="h5">
          The password must contain at least:
        </ListHeading>
        <ListStyled dense>
          <ListItemStyled
            disableGutters
          >
            8 characters &nbsp;{" "}
            {password.length >= 8 ? (
              <FontAwesomeIcon icon={faCheck} color="green" />
            ) : (
              " "
            )}
          </ListItemStyled>
          <ListItemStyled
            disableGutters
          >
            One uppercase character &nbsp;{" "}
            {uppercaseRegExp.test(password) ? (
              <FontAwesomeIcon icon={faCheck} color="green" />
            ) : (
              " "
            )}
          </ListItemStyled>
          <ListItemStyled
            disableGutters
          >
            One lowercase character &nbsp;{" "}
            {lowercaseRegExp.test(password) ? (
              <FontAwesomeIcon icon={faCheck} color="green" />
            ) : (
              " "
            )}
          </ListItemStyled>
          <ListItemStyled
            disableGutters
          >
            One number &nbsp;{" "}
            {numbersRegExp.test(password) ? (
              <FontAwesomeIcon icon={faCheck} color="green" />
            ) : (
              " "
            )}
          </ListItemStyled>
          <ListItemStyled
            disableGutters
          >
            One special character (~!@#$^*_-+=) &nbsp;
            {specialCharRegExp.test(password) ? (
              <FontAwesomeIcon icon={faCheck} color="green" />
            ) : (
              " "
            )}
          </ListItemStyled>
        </ListStyled>
      </Grid>
    </>
  );
};

export default UiPasswordValidation;
