import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Link,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Asterisk from "./Asterisk";
import StylizedLink from "./StylizedLink";
import HasError from "./HasError";
import PasswordField from "./PasswordField";
import { useOktaAuth } from "@okta/okta-react";
import TermsAndConditions from "./TermsandConditions";
import { useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import useAnalyticsEventTracker from './GA4Tracker/index';
import Cookies from 'universal-cookie';

import useMediaQuery from '@mui/material/useMediaQuery';
import ReactGA from 'react-ga4';
import common from '../config';
import commons from '../Utils/constants';

const config = common.config();
//console.log("🚀 ~ file: FormSubmission.tsx ~ line 32 ~ config", config);

const BoxStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: 6,
  marginRight: 0,
  marginBottom: 45,
  marginLeft: 0,
}));

const DivStyled = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: 6,
  marginRight: 0,
  marginBottom: 45,
  marginLeft: 0,
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  boxShadow: "none",
  textTransform: "none",
  fontWeight: "bold",
  outline: 0,
  position: "relative",
  padding: "1rem 15px",
  border: "none",
  display: "inline-block",
  lineHeight: 1.5,
  backgroundColor: theme.palette.success.main,
  color: theme.palette.primary.light,
  fontSize: "1.2rem",
  minWidth: 152,
  minHeight: 40,
  margin: 0,
  textAlign: "center",
  transition: "none",
  [theme.breakpoints.down(768)]: {
    minWidth: 80,
  },
  "&:hover": {
    backgroundColor: theme.palette.success.main,
  },
  "&:disabled": {
    color: "#fff",
    backgroundColor: "#ddd",
  },
}));




//const FROM_URI_COOKIE = "redirectURL";


const FormSubmission: React.FC = (props) => {
  // Style
  const [email, setEmail] = useState("");
  const { oktaAuth } = useOktaAuth();
  const [sessionToken, setSessionToken] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  // Working
	const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(500));

  const [password, setPassword] = useState("");
  const [username, setUserName] = useState("");
  const [errorValue, setErrorValue] = useState("");
  const [checked, setChecked] = useState(false);
  // const [acceptTermsConditions, setAcceptTermsConditions] = useState(false);
  const [deeplink, setDeeplink] = useState("");
  const [isFromURI, setIsFromURI] = useState(false);

  const [showTermsConditions, setShowTermsConditions] = useState(false);
  const { emailRegexp } = commons;
  const [usernameFirstAccess, setUsernameFirstAccess] = useState(false);
  const [passwordFirstAccess, setPasswordFirstAccess] = useState(false);
  let locationHook = useLocation();
  let location = locationHook.pathname;
 
  
  let isPE = location.match('/producer-engage/login') 
  let category = isPE ? 'Producer_Engage' : 'Customer_Engage';
  const environmentGA =  process.env.REACT_APP_ENV;

  var gaEventTracker = useAnalyticsEventTracker(category);
      
  const queryParameters = new URLSearchParams(window.location.search);
      
  let redirectUri = !window.location.href.match("customer-engage/login") ? config.peRedirectURL: config.ceRedirectURL;
  const cookies = new Cookies();
  let backtoSignIn;

  if(category == "Producer_Engage") {
    backtoSignIn = `${config.baseUrl}/producer-engage/forgotpassword`;
  } else {
    backtoSignIn = `${config.baseUrl}/customer-engage/forgotpassword`;
  }

  useEffect(() => {
    if (!emailRegexp.test(username) && username.length > 1 && usernameFirstAccess) {
      setErrorValue("Please enter a valid email address");
    } else if(username.length <= 1 && usernameFirstAccess) {
      setErrorValue("Please enter an email address");
    } else if ( password.length <= 1 && passwordFirstAccess ) {
      setErrorValue("Please enter a password");
    } else {
      setErrorValue("");
    }
    checkFromURI();


  }, [username, password]);

  const checkFromURI = () => {

    const fromURI: string | null = queryParameters.get("fromURI");
    //console.log("fromURI in FormSubmission : ", fromURI);
    
   // console.log("🚀 ~ file: FormSubmission.tsx ~ line 149 ~ .then ~ fromURI : ", fromURI);
    if(fromURI != undefined  && fromURI?.length > 4){
      //console.log("🚀 ~ file: FormSubmission.tsx ~ line 151 ~ fromURI exists and >4 in length: ",  fromURI);
      setIsFromURI(true);
      setDeeplink(fromURI);
      //console.log("🚀 ~ file: FormSubmission.tsx ~ line 154 ~ fromURI setDeeplink");
    }
  }

  const handleAcceptTermsAndConditions = (acceptedTermsCondtions:boolean) => {
    if(acceptedTermsCondtions){
      setOpen(false)
      setChecked(true)

    }else{
      setOpen(false)
      setChecked(false)
    }

  }

  const passWordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setPasswordFirstAccess(true);
  };

  const userNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
    setUsernameFirstAccess(true);
  };

  const passwordVisibilityHandler = () => {
    setShowPassword((prev) => !prev);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    if (username.length === 0 || password.length === 0) {
      setErrorValue("Enter username and password to sign in.");
    } else {
      oktaAuth
        .signInWithCredentials({ username, password})
        .then(async (res: any) => {
          const sessionToken = res.sessionToken;
          console.log("🚀 ~ file: FormSubmission.tsx ~ line 199 ~ .then ~ sessionToken", sessionToken);

          cookies.set('usersession', res.data._embedded.user, { path: '/' });
          setSessionToken(sessionToken);
       
          /*if (sessionToken) {
            setSessionToken(sessionToken);
            // setCookie on auth
          }*/
          
          gaEventTracker('Login_Success', environmentGA);
            
          //console.log("🚀 ~ file: FormSubmission.tsx ~ line 206 ~ .then ~ redirectUrl : ", deeplink);
         // console.log("🚀 ~ file: FormSubmission.tsx ~ line 211 ~ fromURI : ",  deeplink);

          if (isFromURI) {
            //console.log("🚀 ~ file: FormSubmission.tsx ~ line 214 ~ in fromURI if condition ");
            //after auth remove cookie cleanup
            //cookies.remove(FROM_URI_COOKIE);
            //console.log("🚀 ~ file: FormSubmission.tsx ~ line 213 ~ Removing FROM URI COOKIE");
            console.log("🚀 ~ file: FormSubmission.tsx ~ line 218 ~ setCookieAndRedirect: ", deeplink);
            oktaAuth.session.setCookieAndRedirect(sessionToken, decodeURIComponent(deeplink));
            return;
          }
          // sessionToken is a one-use token, so make sure this is only called once
          else oktaAuth.signInWithRedirect({ sessionToken });
  
        })
        .catch((err) => {
          console.log("🚀 ~ file: FormSubmission.tsx ~ line 194 ~ handleSubmit ~ err", err)
          setErrorValue("Invalid login attempt");
          gaEventTracker('Login_Fail', environmentGA);

        });
    }
    
  };

  const checkHandler = () => {
    setChecked((prev) => !prev);
  };

  return (
    <div>
      
      <HasError text={errorValue} />

      <Box component="form" noValidate onSubmit={handleSubmit}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="Username"
          autoFocus
          onChange={userNameHandler}
        />

        <PasswordField
          showPassword={showPassword}
          passWordHandler={passWordHandler}
          passwordVisibilityHandler={passwordVisibilityHandler}
        />
        <Grid sx={{margin: '8px 0px'}} container alignItems="center">
          <Grid item>
            <Asterisk />
            <FormControlLabel

              control={
                <Checkbox
                  checked={checked}
                  onClick={checkHandler}
                  value="remember"
                  color="primary"
                  required={false}
                />
              }
              style={{marginRight: 8}} label={<Typography variant="body1"> I agree to CopperPoint's </Typography>}
            />
          </Grid>
        <Grid item>
          <div onClick={()=> {
              return setOpen(true);
            }}>
              <Typography variant="body1">
              <Link sx={{  
                color: "#2678f4",
                "&:hover": {
                  color: theme.palette.primary.main,
                  cursor: "pointer"
                  }
                }
              }
              underline="none"
              >
              Terms and Conditions
              </Link>
                </Typography>
          </div>
          
        </Grid>

        </Grid>
        <BoxStyled>
          <StylizedLink text={"Forgot your password?"} value={backtoSignIn} />
            <ButtonStyled disabled={!checked} type="submit">
              Sign In
            </ButtonStyled>
          <Grid>
          </Grid>
     
        </BoxStyled>
      </Box>
      <TermsAndConditions open={open} handleAcceptTermsAndConditions={handleAcceptTermsAndConditions}/> 
    </div>
  );
};

export default FormSubmission;
