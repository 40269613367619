import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CircularProgress from '@mui/material/CircularProgress';

import { useOktaAuth } from "@okta/okta-react";
import { useTheme } from '@mui/material/styles';
import Cookies from 'universal-cookie';

import common from '../../config';
import axiosInstance from "../../axiosConfig";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  useLocation,
  useNavigate
} from "react-router-dom";

const config = common.config();


type Props = {
    redirectUrl?: string;
  };


const BoxStyled = styled(Box)(({ theme }) => ({
    color: theme.palette.primary.main,
    width: "100%",
    margin: 'auto',
    height: "100%",
}));

const Logout: React.FC<Props>  = (props) => {
  const { oktaAuth, authState } = useOktaAuth();
  const [loading, setLoading] = useState(true);
  const [lastname, setLastName] = useState("");

  const theme = useTheme();
  let navigate = useNavigate();

  let locationHook = useLocation();
  let location = locationHook.pathname;
  let isPE = location.match('/producer-engage/logout');
  let category = isPE ? 'Producer_Engage' : 'Customer_Engage';
  let backtoSignIn: string;
  if(category == "Producer_Engage") {
    backtoSignIn = `${config.baseUrl}/producer-engage/login`;
  } else {
    backtoSignIn = `${config.baseUrl}/customer-engage/login`;
  }
  const cookies = new Cookies();
  const usersession = cookies.get('usersession');
 // console.log("🚀 ~ file: Logout.tsx ~ line 52 ~ usersession", usersession)

  useEffect(() => {
    const checkSession = async () => {
      if(usersession && usersession.id){
        //alert('fired logout')
        logout();
      }else{
        //alert('fired else')
        setLoading(false);
        await oktaAuth.signOut({
          clearTokensBeforeRedirect: true,
          revokeAccessToken:true,
          revokeRefreshToken:true,
          postLogoutRedirectUri:backtoSignIn
      });
    };
  
  }
  checkSession();
		
  },[]);


  const logout = async () => {
    try {
     // console.log(axiosInstance);
      let res =  await axiosInstance.post('/logout', {id: usersession.id});
      if(res.status==204){
      //console.log("🚀 ~ file: Logout.tsx ~ line 73 ~ logout ~ res", res)
      //  console.log(res);
        // Remove cookies
        await removeUserSession();
        await oktaAuth.signOut({
             clearTokensBeforeRedirect: true,
             revokeAccessToken:true,
             revokeRefreshToken:true,
             postLogoutRedirectUri:backtoSignIn
         });
        window.location.href = backtoSignIn;
      ///navigate("login");
        // navigate({pathname: backtoSignIn});
    }
    
    
    } catch (e: any) {
    
      //gaEventTracker('Forgot_Password_Fail', environmentGA);
      //setErrorValue("The information entered is not valid. If you need further assistance contact:800.231.1363");
      console.log('error', e)
      setLoading(false);
    } finally{
    
      setLoading(false);

    }
  };

  const removeUserSession = async () => {
    let expireDate = new Date('Thu, 01 Jan 1970 00:00:00 GMT');
   // console.log("🚀 ~ file: Logout.tsx ~ line 98 ~ removeUserSession ~ cookies", cookies)
    cookies.remove(`usersession`,{
      expires: expireDate,
      path:"/",
    });
  }
  // const logout = async () => {
  //   let logoutUrl = `/logout`;
  //   axiosInstance.post(logoutUrl, {
  //     userId: false
  //   })
  //   .then((val: any)=>{
  //     console.log("🚀 ~ file: Logout.tsx ~ line 60 ~ .then ~ err", val);
  //   }).catch((err: any) => {
  //     console.log("🚀 ~ file: Logout.tsx ~ line 61 ~ .then ~ err", err)
  //   }).finally(() => {
  //     setLoading(false);
  //   })
	// 	// await oktaAuth.signOut({
  //   //   revokeAccessToken:true,
  //   //   revokeRefreshToken:true,
  //   //   postLogoutRedirectUri: backtoSignIn as string
  //   // })
  //   // .then((val: any)=>{
  //   //   console.log("🚀 ~ file: Logout.tsx ~ line 60 ~ .then ~ err", val);
  //   // }).catch((err: any) => {
  //   //   console.log("🚀 ~ file: Logout.tsx ~ line 61 ~ .then ~ err", err)
  //   // }).finally(() => {
  //   //   setLoading(false);
  //   // })
  // };

  return (
    <>
			{/* <Layout text={"CustomerEngage Account Management"}>
					<BoxStyled className="auth-component"> */}
							<Grid container justifyContent={'center'}>
                {

                  loading ?
                  <CircularProgress
                    sx={{
                      margin: 'auto'
                    }}
                    size={70}
                    color="primary" 
                  />
                  : null
                }

							</Grid>
					{/* </BoxStyled>
			</Layout> */}
    </>
  );
};

export default Logout;
