import React from "react";
import {
    Typography,
    Grid,
  } from "@mui/material";
import {
    BrowserRouter as Router,
    Route,
    useLocation
  } from "react-router-dom";
  
import Heading from "./Heading";


const NoMatch: React.FC = () => {
    let location = useLocation();
  
    return (
			<Grid container  rowSpacing={1}>
				<Heading text={"Forgot your password?"} />
				<Typography variant="h3">
					{`No match for <code>${location.pathname}</code>`}
				</Typography>
			</Grid>
    );
  }

  export default NoMatch;
