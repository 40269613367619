import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Grid,
  List,
  ListItem,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Asterisk from "../../components/Asterisk";
import Note from "../../components/Note";
import StylizedLink from "../../components/StylizedLink";
import HasError from "../../components/HasError";
import PasswordField from "../../components/PasswordField";
import { useOktaAuth } from "@okta/okta-react";
import commons from '../../Utils/constants';
import UiPasswordValidation from "../../components/UiPasswordValidation";
import common from '../../config';
import useAnalyticsEventTracker from '../../components/GA4Tracker/index';
import { useLocation } from "react-router-dom";
const config = common.config();
  //console.log("🚀 ~ file: FormResetPassword.tsx ~ line 27 ~ config", config)

const BoxStyled = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  width: "100%",
  height: "100%",
}));

const BottomText = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: "block",
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  boxShadow: "none",
  textTransform: "none",
  fontWeight: "bold",
  outline: 0,
  position: "relative",
  padding: "1rem 15px",
  border: "none",
  display: "inline-block",
  lineHeight: 1.5,
  backgroundColor: theme.palette.success.main,
  color: theme.palette.primary.light,
  fontSize: "1.5rem",
  minWidth: 152,
  minHeight: 40,
  marginLeft:'10px',
  margin: 0,
  textAlign: "center",
  transition: "none",
  [theme.breakpoints.down(768)]: {
    minWidth: 80,
  },
  "&:hover": {
    backgroundColor: theme.palette.success.main,
  },
  "&:disabled": {
    color: "#fff",
    backgroundColor: "#ddd",
  },
}));


type Props = {
  children?: React.ReactNode;
  handleSubmit:  React.FormEventHandler<HTMLFormElement>;
  disableButton: Boolean | undefined;
  passWordHandler: (event: React.ChangeEvent<HTMLInputElement>) => void
};


const FormResetPassword: React.FC<Props>  = (props) => {
  const [password, setPassword] = useState("");
  const [errorValue, setErrorValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { uppercaseRegExp, numbersRegExp, specialCharRegExp } = commons;
  let locationHook = useLocation();
  let location = locationHook.pathname;

  let isPE = location.match('/producer-engage/resetpassword');
  let category = isPE ? 'Producer_Engage' : 'Customer_Engage';
  var gaEventTracker = useAnalyticsEventTracker(category);
  let backtoSignIn;
  if(category == "Producer_Engage") {
    backtoSignIn = `${config.baseUrl}/producer-engage/login`;
  } else {
    backtoSignIn = `${config.baseUrl}/customer-engage/login`;
  }
  

  useEffect(() => {
    if (
      password.length >= 8 &&
      uppercaseRegExp.test(password) &&
      numbersRegExp.test(password) &&
      uppercaseRegExp.test(password) &&
      specialCharRegExp.test(password)
    )  {
      // setDisableButton(false);
    } else {
      // setDisableButton(true);
    }
  }, [password]);


  const passwordVisibilityHandler = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div>
      <HasError text={errorValue} />
        <Box component="form" noValidate onSubmit={props.handleSubmit}>
          <PasswordField
            showPassword={showPassword}
            passWordHandler={props.passWordHandler}
            passwordVisibilityHandler={passwordVisibilityHandler}/>
   
          {/* <BoxStyled> */}
          <Grid sx={{ minWidth:'300px' }} container justifyContent={"space-between"} alignItems={"center"}>
          <StylizedLink text={"Back To Sign In"} value={`${backtoSignIn}`} />
            <ButtonStyled
                type="submit"
                disabled={props.disableButton as any}
                sx={{ marginBottom: 4, marginTop: 4, marginLeft:'8px' }}
                >
                Set Password
            </ButtonStyled>
          </Grid>
          {/* </BoxStyled> */}
        </Box>
      <Divider />
    </div>
  );
};

export default FormResetPassword;
