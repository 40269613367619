import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Heading from "../components/Heading";
import AuthHeaderComponent from "../components/AuthHeaderComponent";
import AuthContentComponent from "../components/AuthContentComponent";
import PasswordField from "../components/PasswordField";
import ComponentLink from "../components/ComponentLink";
import useAnalyticsEventTracker from '../components/GA4Tracker/index';
import commons from "../Utils/constants";
import { useOktaAuth } from "@okta/okta-react";

import Layout from "./Layout";
import Note from "../components/Note";
import config from '../config';
import {
  Typography,
  Button,
  TextField,
  List,
  ListItem,
  Divider,
} from "@mui/material";
import UiPasswordValidation from "../components/UiPasswordValidation";
import ErrorModal from "./ErrorPages/ErrorModal";
import axiosInstance from "../axiosConfig";
import HasError from "../components/HasError";
import { AuthnTransaction } from "@okta/okta-auth-js";



const ButtonStyled = styled(Button)(({ theme }) => ({
  boxShadow: "none",
  textTransform: "none",
  fontWeight: "bold",
  outline: 0,
  position: "relative",
  padding: "1rem 15px",
  border: "none",
  display: "inline-block",
  lineHeight: 1.5,
  backgroundColor: theme.palette.success.main,
  color: theme.palette.primary.light,
  fontSize: "1.5rem",
  minWidth: 152,
  minHeight: 40,
  margin: 0,
  textAlign: "center",
  transition: "none",
  [theme.breakpoints.down(768)]: {
    minWidth: 80,
  },
  "&:hover": {
    backgroundColor: theme.palette.success.main,
  },
  "&:disabled": {
    color: "#fff",
    backgroundColor: "#ddd",
  },
}));



//?token=MiLB4mW0HY&firstName=AAA&lastName=Arizona&email=agency-101@mailinator.com
const FormSignUp: React.FC = (props) => {
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const { oktaAuth } = useOktaAuth();

  const [lastName, setLastName] = useState("");
  const [errorValue, setErrorValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  let [searchParams, setSearchParams] = useSearchParams();

  
  let locationHook = useLocation();
  let location = locationHook.pathname;

  let isPE = location.match('/producer-engage/signup') 
  let category = isPE ? 'Producer_Engage' : 'Customer_Engage';
 // console.log('{category}', category);
  const environmentGA =  process.env.REACT_APP_ENV;
  var gaEventTracker = useAnalyticsEventTracker(category);

  const {
    uppercaseRegExp,
    lowercaseRegExp,
    numbersRegExp,
    specialCharRegExp,
    emailRegexp,
  } = commons;

  const [apiError, setApiError] = useState<any>("");
  const [openApiErrorModal, setOpenApiErrorModal] = useState(false);
  const [token, setToken] = useState<String | null>(null);
  let tempToken = searchParams.get('token')

  useEffect(() => {
    let tempEmail = searchParams.get('email')
    let tempLastName = searchParams.get('lastName')
    let tempFirstName = searchParams.get('firstName')
    if(tempToken === null){
      //setToken(tempToken);
      navigate("/", { replace: true });
    }else{
      if (tempEmail) {
        setEmail(tempEmail)
      }
      if (tempLastName) {
        setLastName(tempLastName)
      }
      if (tempFirstName) {
        setFirstName(tempFirstName)
      }
  
      if (
        firstName.length > 0 &&
        lastName.length > 0 &&
        password.length >= 8 &&
        numbersRegExp.test(password) &&
        uppercaseRegExp.test(password) &&
        specialCharRegExp.test(password)&&
        lowercaseRegExp.test(password) 
      ) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    }
  }, [firstName, lastName, password, email]);

  useEffect(() => {
    if (!emailRegexp.test(email) && email.length > 1) {
      setErrorValue("Please enter a valid email address");
    } else {
      setErrorValue("");
    }
  }, [email]);

  const firstNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };
  const lastNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };
  const emailHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const passWordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const passwordVisibilityHandler = () => {
    setShowPassword((prev) => !prev);
  };

  const handleErrorModalAccountExists = () => {
    setOpenApiErrorModal(false);
    setApiError("");
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {  
     
      // let transaction: AuthTransaction =  await oktaAuth.verifyRecoveryToken({recoveryToken: token as string})
      // console.log("🚀 ~ file: PESignup.tsx ~ line 176 ~ handleSubmit ~ transaction", transaction)
      // if(transaction && transaction.verify){
      //   transaction.verify()
      // }
      // event.preventDefault();
      let payload = {
        firstName: firstName,
        lastName: lastName,
        email: email.toLowerCase(),
        password: password,
      };
  
      console.log("Axios Instance ", axiosInstance.getUri());
      let res = await axiosInstance.post("/signup/producer-engage", { payload });

      gaEventTracker('Signup_Success', environmentGA);

      // ReactGA.event({
      //   category: "Login and Signup",
      //   action: "Signup_Success",
      //   //value: 99, // optional, must be a number
      // });
      // if(res)
      // navigate("/producer-engage/login");
      navigate("confirmation");
    } catch (e: any) {
      if (e.response.data.error.toLowerCase() === "sign up criteria errors") {
        const errorCauses = e.response.data.errorCause;
        var dataElement = errorCauses[0];
        if (dataElement.toLowerCase() === "information not valid") {
          setApiError(
            `The information entered is not valid. If you need further assistance contact 800.231.1363.`
          );
          setOpenApiErrorModal(true);
        } else if (dataElement.toLowerCase() === "account exists") {
        setApiError(
            <p>
              Please select <em>Forgot your password?</em> at the Sign In page, or call 1.800.231.1363 for further assistance.
              
            </p>
          );
          setOpenApiErrorModal(true);
        }
      } else {
        setApiError(`Error`);
        setOpenApiErrorModal(true);
      }
      gaEventTracker('Signup_Fail action', environmentGA);

      // ReactGA.event({
      //   category: "Login and Signup",
      //   action: "Signup_Fail",
      //   //value: 99, // optional, must be a number
      // });
      console.log("The error is ", e);
    }finally{
      // navigate("confirmation");
    }
  };

  const ListHeading = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(2),
    fontWeight: "bold",
    color: "#848484",
  }));

  const ListStyled = styled(List)(({ theme }) => ({
    listStyleType: "disc",
    padding: 0,
  }));

  const ListItemStyled = styled(ListItem)(({ theme }) => ({
    display: "list-item",
    marginLeft: theme.spacing(2),
    paddingBottom: 0,
    fontWeight: "bold",
    color: "#848484",
  }));

  const ButtonWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "6px 0 45px",
    marginBottom: theme.spacing(1),
  }));

  return (
    <div>

      <>
        <HasError text={errorValue} />
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="firstname"
            label="First Name"
            name="firstName"
            disabled={true}
            variant="filled"
            autoComplete="First Name"
            value={firstName}
            autoFocus

            onChange={firstNameHandler}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="lastname"
            label="Last Name"
            variant="filled"
            name="lastname"
            value={lastName}
            disabled={true}
            autoComplete="Last Name"
            onChange={lastNameHandler}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="Email"
            value={email}
            variant="filled"
            autoFocus
            disabled={true}
            onChange={emailHandler}
          />
          <PasswordField
            showPassword={showPassword}
            passWordHandler={passWordHandler}
            passwordVisibilityHandler={passwordVisibilityHandler}
          />
          <ButtonWrapper>
            <ButtonStyled
              type="submit"
              disabled={disableButton}
              sx={{ marginBottom: 2, marginTop: 1 }}
            >
              Sign Up
            </ButtonStyled>
          </ButtonWrapper>
        </Box>
        <Divider />
        {/* <ComponentLink text={"Sign In"} value={"/customer-engage/login"} /> */}
        <UiPasswordValidation password={password} />

        <ErrorModal
          open={openApiErrorModal}
          handleErrorModal={handleErrorModalAccountExists}
          error={apiError}
        />
      </>
    
    </div>
  );
};



const SignUp: React.FC = (props) => {
  const BoxStyled = styled(Box)(({ theme }) => ({
    color: theme.palette.primary.main,
    width: "100%",
    height: "100%",
  }));

  return (
    <>
      {/* <Note/> */}
        <Heading text={"Sign Up"} />
      <FormSignUp />
    </>
  );
};

export default SignUp;
