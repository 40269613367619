import React from 'react';
import { Route, useNavigate } from 'react-router-dom';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import common from '../config';

const config = common.config();

const OktaWithRouterAccess = (props: any) => {

  const navigate = useNavigate();

  
  const onAuthRequired = () => {
    navigate('/login');
  };


  const oktaAuth = new OktaAuth({
    issuer: config.issuer,
    clientId: config.clientId, 
    redirectUri: !window.location.href.match("customer-engage/login") ? config.peRedirectURL: config.ceRedirectURL,
    pkce: false
  });

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    // console.log("🚀 ~ file: OktaConfig.tsx ~ line 27 ~ restoreOriginalUri ~ originalUri", originalUri)
    // console.log('{restoreOriginalUri}', toRelativeUrl(originalUri, window.location.origin), {replace:true})
    // console.log("🚀 ~ file: OktaConfig.tsx ~ line 29 ~ restoreOriginalUri ~ window.location.origin", window.location.origin)
    
    navigate(toRelativeUrl(originalUri, window.location.origin), {replace:true});
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
     {props.children}
    </Security>
  );
};
export default OktaWithRouterAccess;
