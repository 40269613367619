import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const HasError: React.FC<{ text: string }> = (props) => {
  const BoxStyled = styled(Box)(({ theme }) => ({
    color: "#f54849",
    overflow: "hidden",
    transition: "0 .3s",
    fontSize:"1.2rem",
    maxHeight: props.text.length === 0 ? 0 : 999,
    marginBottom: theme.spacing(2),
  }));

  return <BoxStyled>{props.text}</BoxStyled>;
};

export default HasError;
