import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Box, Grid, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinusCircle
} from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@mui/material/styles";
import { Avatar } from '@mui/material';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ErrorModal(props: any) {

  const theme = useTheme();

  return (
    <div>
      <Dialog
        scroll="paper"
        open={props.open}
        sx={{
          width: "750px",
          margin: "auto",
        }}
        keepMounted
        onClose={props.handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
        <div style={{
          display: 'flex',
          alignItems: 'center'
}}>
              <Avatar sx={{ width:24, height: 24, bgcolor: "#ffcccb" }}>
              <FontAwesomeIcon color="red" icon={faMinusCircle}/>
              </Avatar>
            
            
              <Typography variant="h4" component="div">
                {props.header?? "Error"}
              </Typography>
              </div>
            
            
          
        </DialogTitle>
        <DialogContent
          id="dialog-content"
          sx={{
            padding: "0px 0px",
          }}
        >
          <DialogContentText
            sx={{ padding: "0px 25px" }}
            id="alert-dialog-slide-description"
          >
            <Box>
              <Typography gutterBottom component="div">
                {props.error}
              </Typography>
            </Box>
          </DialogContentText>

          <Box
            sx={{
              width: "100%",
              height: "80px",
              backgroundColor: "#ebebeb",
              borderTop: "1px solid #bf521a",
            }}
          >
            <Grid
              sx={{
                height: "100%",
                width: "100%",
              }}
              container
              justifyContent={"flex-end"}
              alignContent="center"
            >
              <Grid item>
                <Button
                  sx={{
                    width: "150px",
                    height: "40px",
                    fontSize: " 1.3rem",
                    margin: "15px"
                  }}
                  variant="contained"
                  color="success"
                  onClick={() => props.handleErrorModal()}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
