import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Asterisk from "../../components/Asterisk";
import Note from "../../components/Note";
import StylizedLink from "../../components/StylizedLink";
import HasError from "../../components/HasError";
import { useOktaAuth } from '@okta/okta-react';
import { useNavigate, useSearchParams, useParams, useLocation } from "react-router-dom";
import Heading from "../../components/Heading";
import AuthHeaderComponent from "../../components/AuthHeaderComponent";
import AuthContentComponent from "../../components/AuthContentComponent";
import commons from "../../Utils/constants";
import UiPasswordValidation from "../../components/UiPasswordValidation";
import PasswordField from "../../components/PasswordField";
import Layout from "../Layout";
import FormResetPassword from "./FormResetPassword";
import axiosInstance from "../../axiosConfig";
import common from '../../config';
import ReactGA from 'react-ga4';
import useAnalyticsEventTracker from '../../components/GA4Tracker/index';
import { AuthnTransaction } from "@okta/okta-auth-js";
import ErrorModal from "../ErrorPages/ErrorModal";

const config = common.config();


const BoxStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: 6,
  marginRight: 0,
  marginBottom: 45,
  marginLeft: 0,
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  boxShadow: "none",
  textTransform: "none",
  fontWeight: "bold",
  outline: 0,
  position: "relative",
  padding: "1rem 15px",
  border: "none",
  display: "inline-block",
  lineHeight: 1.5,
  backgroundColor: theme.palette.success.main,
  color: theme.palette.primary.light,
  fontSize: "1.5rem",
  minWidth: 152,
  minHeight: 40,
  margin: 0,
  textAlign: "center",
  transition: "none",
  [theme.breakpoints.down(768)]: {
    minWidth: 80,
  },
  "&:hover": {
    backgroundColor: theme.palette.success.main,
  },
  "&:disabled": {
    color: "#fff",
    backgroundColor: "#ddd",
  },
}));

//Working


const ResetPassword: React.FC = (props) => {
  
  const [password, setPassword] = useState("");
  const [errorValue, setErrorValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [disableButton, setDisableButton] = useState<Boolean>(true);
  const {uppercaseRegExp, lowercaseRegExp, numbersRegExp, specialCharRegExp } = commons;
  const { oktaAuth } = useOktaAuth();
  let navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [apiError, setApiError] = useState<any>("");
  let locationHook = useLocation();
  let location = locationHook.pathname;
  const [openApiErrorModal, setOpenApiErrorModal] = useState(false);

  let isPE = location.match('/producer-engage/resetpassword');
  let category = isPE ? 'Producer-Engage' : 'Customer-Engage';
  const environmentGA =  process.env.REACT_APP_ENV;

  var gaEventTracker = useAnalyticsEventTracker(category);
  const [token, setToken] = useState<String | null>('');
 // const { token } = useParams();


  const [ transaction, setTransaction ]  = useState<AuthnTransaction>();

  useEffect(() => {

    let tempToken = searchParams.get('token')
    if (!tempToken) {
      navigate("/", { replace: true });
    }
    setToken(tempToken)
    if (
      password.length >= 8 &&
      uppercaseRegExp.test(password) &&
      numbersRegExp.test(password) &&
      uppercaseRegExp.test(password) &&
      specialCharRegExp.test(password) && 
      lowercaseRegExp.test(password)
    )  {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }


  }, [password]);





const recoverPassword = async ()  =>  {


  oktaAuth.verifyRecoveryToken({
    recoveryToken: token as string
}).then(function(transaction: AuthnTransaction) {

    if(transaction.resetPassword){
      transaction?.resetPassword({
          newPassword: password
      }).then(()=>{
        gaEventTracker('Password_Reset_Success', environmentGA);

        navigate({pathname: `confirmation`}, {replace: true});
      }).catch((e)=>{
        console.log("🚀 ~ file: FormSignUp.tsx:148 ~ handleSubmit ~ e", e);
        console.log("🚀 ~ file: FormSignUp.tsx:198 ~ handleSubmit ~ e", e.errorCauses[0]);
        if (e.errorSummary.toLowerCase() === "the password does not meet the complexity requirements of the current password policy.") {
          const errorCauses = e.errorCauses;
          console.log("🚀 ~ file: FormSignUp.tsx:198 ~ handleSubmit ~ errorCauses", errorCauses)
          var dataElement = errorCauses[0].errorSummary;
          if (dataElement.toLowerCase() === "password cannot be your current password") {
            setApiError(`Your new password cannot be the same as any of your most recent passwords. Please enter a new password.`);
            setOpenApiErrorModal(true);
          } else if (dataElement.toLowerCase() === "account exists") {
            setApiError(
              <p>
                Please select <em>Forgot your password?</em> at the Sign In page, or call 1.800.231.1363 for further assistance.
              </p>
            );
            setOpenApiErrorModal(true);
          }
        } else {
          setApiError(`Error`);
          setOpenApiErrorModal(true);
        }
      });

    }

    // res.send('<p>Password reset successfully</p>')
}).catch(function(e){

    if (e.errorSummary === "You have accessed an account recovery link that has expired or been previously used.") {
      const errorCauses = e.errorCauses;
      console.log("🚀 ~ file: FormSignUp.tsx:198 ~ handleSubmit ~ errorCauses", errorCauses)
    
        setApiError(e.errorSummary);
        setOpenApiErrorModal(true);
    }else
    {
      setApiError(e.errorSummary);
      setOpenApiErrorModal(true);
    }
    gaEventTracker('Password_Reset_Fail', environmentGA);

    console.error(e);
});




  // try {
  //   let AuthnTransaction:AuthnTransaction= await oktaAuth.verifyRecoveryToken({recoveryToken: token as string})
  //   console.log('{AuthnTransaction}', AuthnTransaction);
  //   setTransaction(AuthnTransaction)
  // } catch (error) {
  //   console.log('error', error);
  //   navigate("/", { replace: true });
  // }
}


const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
  event.preventDefault();

  recoverPassword()
// if(transaction){

//   resetPassword(transaction)
// }
};



  const passWordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };


  const passwordVisibilityHandler = () => {
    setShowPassword((prev) => !prev);
  };

  const handleErrorModalAccountExists = () => {
    setOpenApiErrorModal(false);
    setApiError("");
  };

  return (
    <>
      <Grid container alignItems={"center"} rowSpacing={1}>
      <Grid item spacing={1}>
        <Heading text={"New Password"} /> 
      </Grid>
        <HasError text={errorValue} />
      <Grid item>
        <FormResetPassword handleSubmit={handleSubmit} disableButton={disableButton} passWordHandler={passWordHandler}/>
      </Grid>
      <Divider />
      <UiPasswordValidation password={password}/>
      </Grid>    

      
      <ErrorModal
          open={openApiErrorModal}
          handleErrorModal={handleErrorModalAccountExists}
          error={apiError}
        />
    </>
  );
};

export default ResetPassword;
