import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import logo from "../copperpoint_alaskanational_logo.png";

const AuthHeaderComponent: React.FC<{ text: string }> = (props) => {
  const AuthHeader = styled(Grid)(({ theme }) => ({
    backgroundColor:"black",
    paddingTop: theme.spacing(12),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up(1025)]: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: "25%",
      maxWidth: "25%",
    },
    [theme.breakpoints.between(641, 1025)]: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: "33.333%",
      maxWidth: "33.333%",
    },
    [theme.breakpoints.between(1, 641)]: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: "100%",
      maxWidth: "100%",
    },
    [theme.breakpoints.down("phoneLandscape")]: {
      display: "flex",
      paddingTop: "24px",
    },
  }));

  const AuthLogo = styled(Grid)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
    alignItems: "center",
  }));

  const AuthLogoImg = styled("img")(({ theme }) => ({
    width: "100%",
    alt: "GuideWire",
    maxWidth: "100%",
    height: "auto",
    verticalAlign: "middle",
    border: 0,
  }));

  const AuthPortal = styled(Typography)(({ theme }) => ({
    color: "#fff",
    fontWeight: 400,
    maxWidth: '290px',
    textAlign: "center",
    margin: '30px auto 5px',
    fontSize: "2.4rem",
    [theme.breakpoints.down("phoneLandscape")]: {
      fontSize: "2.1rem",
      marginLeft: theme.spacing(3),
    },
  }));

  return (
    <AuthHeader>
      <AuthLogo>
        <AuthLogoImg src={logo} />
      </AuthLogo>
      <AuthPortal variant="h1">{props.text}</AuthPortal>
    </AuthHeader>
  );
};

export default AuthHeaderComponent;
