import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Asterisk from "../../components/Asterisk";
import Note from "../../components/Note";
import StylizedLink from "../../components/StylizedLink";
import HasError from "../../components/HasError";
import { useOktaAuth } from '@okta/okta-react';
import AuthHeaderComponent from "../../components/AuthHeaderComponent";
import AuthContentComponent from "../../components/AuthContentComponent";
import { useNavigate } from "react-router-dom";
import Layout from "../Layout";
import Heading from "../../components/Heading";
import { useLocation } from 'react-router-dom';

import common from '../../config';

const config = common.config();

const BoxStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: 6,
  marginRight: 0,
  marginBottom: 45,
  marginLeft: 0,
}));

const ResetPasswordConfirmation: React.FC = (props) => {
    let locationHook = useLocation();
    let location = locationHook.pathname;
  //  console.log('{location: FormSubmission}', location);
  
    let isPE = location.match('/producer-engage/resetpassword/confirmation') 
    let category = isPE ? 'Producer_Engage' : 'Customer_Engage';
    let backtoSignIn;
    if(category == "Producer_Engage") {
      backtoSignIn = `${config.baseUrl}/producer-engage/login`;
    } else {
      backtoSignIn = `${config.baseUrl}/customer-engage/login`;
    }
  return(
    <>
    {/* <AuthContentComponent> */}
      <Grid container sx={{
        position: 'relative',
        top: '20%'
      }}>
        <Grid container alignContent={"space-evenly"}>
          <Typography variant="h2">
            {`Password successfully changed`}
          </Typography>
        </Grid>
        <Grid  >
            <Grid sx={{marginTop: 6}}>
                <Typography variant="body1" display={'block'}>
                {`Return to Sign In and enter your new password.`}
                </Typography>
            </Grid>
            <Grid container sx={{marginTop: 5}}>
                <StylizedLink
                text={"Back to Sign In"}
                value={`${backtoSignIn}`} 
                />

            </Grid>
          
        </Grid>
      </Grid>

    {/* </AuthContentComponent> */}
    </>
  )

};


export default ResetPasswordConfirmation;