import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function TermsAndConditions(props: any) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const handleClickOpen = () => {
    setOpen(true);
  };


  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>

      <Dialog
			scroll="paper"
        open={props.open}
        // TransitionComponent={Transition}
		sx={{
		  width: '750px',
		  margin: 'auto'
		}}
        keepMounted
        onClose={props.handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
				<Typography variant="h4" gutterBottom component="div">
					{"COPPERPOINT INSURANCE COMPANIES POLICYHOLDER PORTAL TERMS OF USE"}
				</Typography>
					</DialogTitle>
        <DialogContent id="dialog-content" sx={{
					padding: "0px 0px"
				}}>
					<DialogContentText sx={{padding: "0px 25px"}} id="alert-dialog-slide-description">
						<Box>
							<Typography  gutterBottom component="div">
								{`For the purposes of these Terms of Use: "Company" refers to CopperPoint Insurance Company and its subsidiaries; " You" or “Your” refers to any person or organization using or accessing the Policyholder Portal other than Company.`}
							</Typography>
							<Typography variant="body1" gutterBottom component="div">
								{`By using the Company\’s Policyholder Portal services ("Services") provided on the Company’s Policyholder Portal website ("Policyholder Portal"), You agree to these Terms of Use ("Terms"). If you do not agree to the Terms, You may not use or access the Services.`}
							</Typography>
							<Typography variant="body1" gutterBottom component="div">
								{`	If You are subject to a separate Agency Agreement, these Terms are in addition thereto.`}
							</Typography>
						

							<Typography variant="h5" gutterBottom>
							<strong>
								{`1. Privacy, Confidentiality and Non-disclosure`}
							</strong>
							</Typography>
							<Typography variant="body1" gutterBottom>
								{`You agree that confidential information disclosed to You through the Agency Portal shall remain confidential and shall not be disclosed except for the purpose for which the Company has provided such information to You or as may be required by law.`}
							</Typography>
							<Typography variant="body1" gutterBottom>
								{`You agree that the Services are governed by our Online Privacy Policy and you consent to all actions we take with respect to your information consistent with the Online Privacy Policy.`}
							</Typography>

							<Typography variant="h5" gutterBottom>
							<strong>
								{`2. Limited Use of Services`}
						</strong>
							</Typography>
							<Typography variant="body1" gutterBottom>
								{`You agree to use the Services only for purposes that are permitted by (a) the Terms and (b) any applicable law, including any state, federal or international laws, regulations or any other government requirements. You further agree not to use the Services in any manner that could damage, disable, overburden or impair any Company server or the network(s) connected to any Company server, or interfere with any other party's use of any Services.`}
							</Typography>
							<Typography variant="body1" gutterBottom>
								{`You shall use the content provided on the Agency Portal only for the business purpose for which it is intended and in the normal course of business and shall not use the content with or for any other person or entity.`}
							</Typography>
							<Typography variant="h5" gutterBottom>
								<strong>
									{`3. Your Passwords and Account Security`}
								</strong>
							</Typography>
							<Typography variant="body1" gutterBottom>
								{`Agency shall appoint a person to act as Agency Portal administrator for the Agency ("Administrator"). The Administrator shall provide users with a user name and password to access to the Agency Portal. Immediately upon the termination of employment of any individual employee with a separate user ID, Administrator is responsible for promptly disabling that employee's user name and password.`}
							</Typography>
							<Typography variant="body1" gutterBottom>
								{`You are entirely responsible for maintaining the confidentiality of the password and account, and for any and all activities that occur under the password and account. Company is authorized to act on any instructions received under Your password without any further verification.`}
							</Typography>
							<Typography variant="body1" gutterBottom>
								{`Company will not be liable for any loss that You may incur as a result of someone else using Your password or account, either with or without Your knowledge. You understand that You could be held liable for losses incurred by Company or another party due to someone else using Your account or password. You agree to notify Company immediately upon discovery of any unauthorized use of Your account or any other breach of security of the account or the information contained therein. Notification must be made immediately to CopperPoint General Counsel, by telephone at (602) 631-2524 or by e-mail at legal@copperpoint.com and also ITSecurity@CopperPoint.com and contracts@CopperPoint.com.`}
							</Typography>
							<Typography variant="body1" gutterBottom>
								{`Company may deny access or block any transaction made under Your password without prior notice if Company believes Your password is being used by someone other than You or for any other reason but it is under no obligation to do so absent notification from You.`}
							</Typography>

							<Typography  variant="h5"  gutterBottom>
								<strong>
									{`4. Third-Party Sites`}
								</strong>
							</Typography>
							<Typography variant="body1" gutterBottom>
								{`Company's Agency Portal may include links to other sites on the Internet that are owned and operated by other companies. You acknowledge that Company is not responsible for the availability of, or the content located on or through, any third-party site. Your use of those third-party sites is subject to the terms of use and privacy policies of each site and Company is not responsible therein. Company encourages You to review said privacy policies of third-parties' sites.`}
							</Typography>

							<Typography variant="h5" gutterBottom>
								<strong>
									{`5. Disclaimer of Warranties and Limitation of Liability`}
								</strong>
							</Typography>
							<Typography variant="body1" gutterBottom>
								{`COMPANY DOES NOT GUARANTEE THAT THE AGENCY PORTAL WILL BE ERROR-FREE OR CONTINUOUSLY AVAILABLE OR THAT IT WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. USE OF THE AGENCY PORTAL IS “AS IS” AND COMPANY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED. TO THE EXTENT NOT PROHIBITED BY APPLICABLE LAW, COMPANY WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF OR INABILITY TO USE THE AGENCY PORTAL. YOU EXPRESSLY AGREE THAT YOUR USE OF THE AGENCY PORTAL IS SOLELY AT YOUR OWN RISK.`}
							</Typography>
							<Typography variant="body1" gutterBottom>
								{`COMPANY MAY MAKE CHANGES TO OR ELIMINATE THE AGENCY PORTAL AND/OR THE SERVICE(S) AT ANY TIME WITH OR WITHOUT NOTICE.`}
							</Typography>

							<Typography variant="h5" gutterBottom>
								<strong>
								{`6. Copyright and Trademark Information`}
								</strong>
							</Typography>
							<Typography variant="body1" gutterBottom>
								{`You acknowledge and agree that Company or its affiliates and licensors own all legal right, title and interest in and to the Agency Portal and Services, including any intellectual property rights which subsist in the Agency Portal and Services, whether those rights happen to be registered or not, and wherever in the world those rights may exist.`}
							</Typography>
							<Typography variant="body1" gutterBottom>
								{`In the course of transactions directly related to business on behalf of the Company, You may use the Agency Portal in the solicitation or servicing of businesses. Other than as permitted pursuant to these Terms, You may not copy, reproduce, republish, modify, upload, post, transmit, distribute, decompile, reverse engineer, disassemble, data extract, data mine, rent, lease, loan, sell or create derivative works of any kind based on materials on this Agency Portal, in whole or in part, including, but not limited to text, images, audio and/or video.`}
							</Typography>
							<Typography variant="body1" gutterBottom>
								{`You shall not assert or possess any right or claim of ownership to the Agency Portal or Services. The Agency Portal and its content and these Terms may not be altered, assigned, sold or transferred by You without the express written consent of the Company. Any limited authorization is not a transfer of title in the materials and by Your use of the Agency Portal you acknowledge that You do not acquire any license, ownership or other rights to the materials. If You breach any of these Terms, Your authorization to use the Agency Portal automatically terminates and You must immediately destroy any materials obtained on the Agency Portal retained in any form, unless prevented by law.`}
							</Typography>
							<Typography variant="h5" gutterBottom>
								{`7. Miscellaneous`}
							</Typography>
							<Typography variant="body1" gutterBottom>
								{`Company may record, use and disclose any information (including transactional information) related to Your access and use of Agency Portal for any lawful reason including, but not limited to: (1) responding to emergencies; (2) complying with the law (e.g., a lawful subpoena); (3) protecting Company's rights or property and those of its customers; or (4) protecting users of the Services from fraudulent, abusive or unlawful use of such Services. Indirect or attempted breaches of these Terms, and actual or attempted breaches by You or a third party on Your behalf may be considered breaches of these Terms by You or such third party.`}
							</Typography>
							<Typography variant="body1" gutterBottom>
								{`Company may, at any time, terminate this agreement with You:`}
							</Typography>
							<ol>
								<li>
									<Typography variant="body1" gutterBottom>
										{`If You have breached any provision of the Terms (or have acted in a manner which clearly shows that You do not intend or are unable to comply with the provisions of the Terms); or`}
									</Typography>
								</li>
								<li>
									<Typography>
										{`If Company is required to do so by law (for example, where the provision of the Services to You is, or becomes, unlawful); or`}
									</Typography>
								</li>
								<li>
									<Typography>
										{`For infringement of intellectual property rights or other proprietary rights including, without limitation, material protected by copyright, trademark, patent, trade secret or other intellectual property right.`}
									</Typography>
								</li>
								<li>
									<Typography>
										{`For violating the rules, regulations or policies that apply to any third-party network, server, computer database or website that You access; or`}
									</Typography>
								</li>
								<li>
									<Typography>
										{`For disseminating or posting harmful content including, without limitation, viruses, Trojan horses, worms, time bombs, zombies, cancelbots or any other computer programming routines that may damage, interfere with, secretly intercept or seize any system, program, data or personal information`}
									</Typography>
								</li>
							</ol>
							<Typography variant="body1" gutterBottom>
								{`Any cause of action You may have with respect to Your use of the Agency Portal must be commenced within six (6) months after the claim or cause of action arises.`}
							</Typography>
							<Typography variant="body1" gutterBottom>
								{`Any failure by Company to insist upon or enforce performance of any provision of these Terms will not be construed as a waiver of any provision or right. Neither the course of conduct between the parties nor trade practice will act to modify any provision in these Terms.`}
							</Typography>
							<Typography variant="body1" gutterBottom>
								{`If any provision of these Terms is deemed unlawful, void, or for any reason unenforceable, then that provision will be deemed severable from these Terms so that it does not affect the validity and enforceability of any remaining provisions.`}
							</Typography>
						</Box>
          </DialogContentText>

					<Box
						sx={{
							width: "100%",
							height: "80px",
							backgroundColor: '#ebebeb',
							borderTop: "1px solid #bf521a",
							
						}}
						>
							<Grid sx={{
								height: '100%',
								width: "100%",
								margin: "10px ",
							}} container justifyContent={"flex-end"} alignContent="center">
								<Grid item>
								<Button 
									sx={{
										backgroundColor: 'white',
										width: "150px",
										height: "40px",
										marginRight: "5px",
										fontSize:" 1.2rem"
									}} 
								variant='outlined' color='success' 
								onClick={()=>props.handleAcceptTermsAndConditions(false)}>I Disagree</Button>
								<Button sx={{
											width: "150px",
											height: "40px",
											fontSize:" 1.3rem"
									}} variant='contained' color='success' onClick={()=>props.handleAcceptTermsAndConditions(true)}>I Agree</Button>
								</Grid>
							</Grid>

					</Box>
        </DialogContent>
        {/* <DialogActions disableSpacing>
					<Box
						sx={{
							width: "100%",
							height: "80px",
							backgroundColor: '#ebebeb',
							borderTop: "1px solid #bf521a",
						}}
						>
							<Grid container justifyContent={"flex-end"}>
								<Grid item>
									<Button sx={{
											backgroundColor: 'white'
									}} variant='outlined' color='success' onClick={()=>props.handleAcceptTermsAndConditions(false)}>I Disagree</Button>
									<Button 
									variant='contained' color='success' onClick={()=>props.handleAcceptTermsAndConditions(true)}>I Agree</Button>
								</Grid>
							</Grid>

					</Box>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}