import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axiosInstance from "../../axiosConfig";
import { Outlet, Navigate, redirect } from "react-router-dom";
import Layout from "../Layout";
import TileLink from "../../components/TileLink";
import { Divider, Typography } from "@mui/material";
import StylizedLink from "../../components/StylizedLink";
import ReactGA from 'react-ga4';
import { Link as BrowserLink, useLocation, useNavigate } from "react-router-dom";
import getCookie from "../../Utils/getCookie";
import Cookies from 'universal-cookie';
import common from '../../config';
const config = common.config();


const theme = createTheme();



export default function Home(props:any) {
  //console.log("🚀 ~ file: Home.tsx:27 ~ Home ~ props", props)
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [deeplink, setDeeplink] = useState("");
  const [appNameCookie, setAppNameCookie] = useState<any>(getCookie("appNameCookie"));
  const [showView, setShowView] = useState<boolean>(false);
  const [view ,setView] = useState<any>(null);
  // const [view, setView] = useState(null);
  const [isFromURI, setIsFromURI] = useState(false);

  const [isFromPE, setisFromPE] = useState(false);
  const navigate = useNavigate();

  ReactGA.send("pageview");


  const queryParameters = new URLSearchParams(window.location.search);
  
  // let view;

  useEffect( ()=>{
    let baseUrl = config.baseUrl;
    let appNameCookieTemp = getCookie("appNameCookie");
   let tempVal = checkFromURI();
    if (appNameCookieTemp == "") {
      setShowView(true)
    }else if (appNameCookieTemp as string == "CE"){
      setShowView(false);
      if(tempVal!=""){
        //redirect(`customer-engage/login/?fromURI=${tempVal}`);
        window.location.replace(`${baseUrl}/customer-engage/login/?fromURI=${tempVal}`);
      } else{
        window.location.replace(`${baseUrl}/customer-engage/login`);
      }
     // redirect(`customer-engage/login`);

    }else if (appNameCookieTemp as string == "PE"){
      setShowView(false);
        if(tempVal != ""){

         // redirect(`producer-engage/login/?fromURI=${tempVal}`);
          window.location.replace(`${baseUrl}/producer-engage/login/?fromURI=${tempVal}`);
        }else{
          window.location.replace(`${baseUrl}/producer-engage/login`);
        }
        //redirect(`producer-engage/login`);

     // return <Navigate to={`producer-engage/login/?fromURI=+ ${deeplink}`} />
  
      //return navigate(`producer-engage/login/?fromURI=+ ${deeplink}`);
     //}
    //  return <Navigate to={`producer-engage/login`} />
    }
    



  },[appNameCookie])
// get cookie function

const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
  event.preventDefault();
  // call auth api to authenticate user
};

const checkFromURI = () => {

  const fromURI: string | null = queryParameters.get("fromURI");

  if(fromURI != undefined  && fromURI?.length > 4){
    setIsFromURI(true);
    setDeeplink(fromURI);
    return fromURI;
  }
}

const renderApploginLinks = () => {
  return (
    
    // <></>
    <Layout text={""}>
      <Box sx={{position: "relative", top: "12%", textAlign:"center"}}>
      <Box sx={{marginBottom: "25px"}} textAlign="center" marginTop={1}>
        <Typography  variant="h4">
          {"Your session has timed out."}
        </Typography>
        <Typography sx={{marginBottom: "55px"}} variant="h4">
          {"Please choose a portal below to sign in again."}
        </Typography>
      </Box>
        <Grid container direction={"row"} justifyContent={"space-between"} spacing={2}>
        { isFromURI ?
              <Grid item>
                <TileLink path={"producer-engage/login/?fromURI="+ deeplink} TileHeader="Agent Login"/>
                {/* <TileLink path={"http://login-uat3.copperpoint.com/?fromURI="+redirectUrl} link={redirectUrl} TileHeader="UAT3" /> */}
              </Grid> :
              <Grid item>
               <TileLink path={'producer-engage/login'} TileHeader="Agent Login"/>
              </Grid>
      }
        {/* ------------------------ */}
      

        { isFromURI ?
            <Grid item>
              <TileLink path={"customer-engage/login/?fromURI="+ deeplink} TileHeader="Customer Login"/>
            </Grid> :
            <Grid item>
              <TileLink path={'customer-engage/login'} TileHeader="Customer Login"/>
            </Grid>
        }
          <Grid sx={{margin: 'auto'}} item alignSelf={"flex-end"}>
          <Link href="https://www.copperpoint.com/" sx={{position: "relative", top:"15px",
          color: "#2678f4",
          fontSize:"14px",
          fontWeight:"500",
            "&:hover": {
              color: theme.palette.primary.main,
              cursor: "pointer"
            },}} underline="always">
            Return to CopperPoint home page
          </Link>
          </Grid>
      <Outlet />
        </Grid>
      </Box>
</Layout>
  );
  
}

const renderRedirect =() => {
  if (appNameCookie === "PE"){
    // sessionStorage.setItem("appNameSession", "PE"); 
    // localStorage.setItem("appNameLocalstorage", "PE");
    // document.cookie = "appNameCookie=PE";
    //check session storage
     if(deeplink != ""){
      return <Navigate to={`producer-engage/login/?fromURI=+ ${deeplink}`} />
  
      //return navigate(`producer-engage/login/?fromURI=+ ${deeplink}`);
     }
     return <Navigate to={`producer-engage/login`} />
    // navigate(`producer-engage/login`);
    //navigate("/", { replace: true });
  
    } else if(appNameCookie) {
      if(deeplink != ""){
        return <Navigate to={`customer-engage/login/?fromURI=+ ${deeplink}`} />
         //clean up cookie and session for redirect
        // return navigate(`customer-engage/login/?fromURI=+ ${deeplink}`);
      }
      return <Navigate to={`customer-engage/login`} />
     // navigate(`customer-engage/login`);
      //navigate("/", { replace: true });
    } 

}




  return(
    <>
    

    {
    showView?
    renderApploginLinks()
    : null

    }
    
    </>
  )

}
