import React from "react";
import { Link } from "@mui/material";
import { Link as BrowserLink } from "react-router-dom";
import { styled } from "@mui/material/styles";

const ComponentLink: React.FC<{ text: string; value: string }> = (props) => {
  return (
    <Link
      underline="none"
      sx={{
        color: "#2678f4",
        fontSize:"1.2rem",
        "&:hover": {
          color: "primary.main",
        },
      }}
      component={BrowserLink}
      to={props.value}
    >
      {props.text}
    </Link>
  );
};

export default ComponentLink;
