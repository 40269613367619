import * as React from 'react';
import { useState } from 'react';
import { Paper, Typography, Link as MuiLink, Box, IconButton } from '@mui/material';
// import ContactsIcon from '@mui/icons-material/AccountCircle';
// import DealIcon from '@mui/icons-material/MonetizationOn';
// import { useCreatePath, SelectField, useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';
import { styled } from "@mui/material/styles";

import logo from "../copperpoint-logo_new.png";
// import { sectors } from './sectors';
// import { CompanyAvatar } from './CompanyAvatar';
// import { Company } from '../types';
const LogoImg = styled("img")(({ theme }) => ({
    width: "100%",
    alt: "GuideWire",
    maxWidth: "100%",
    height: "auto",
    verticalAlign: "middle",
	marginRight: "5px",
    border: 0,
  }));

export const TileLink = (props: {  path: string, TileHeader: string, link?: any  }) => {
    const [elevation, setElevation] = useState(1);

	return (
		<MuiLink
			component={Link}
			to={props.path}
			underline="none"
			onMouseEnter={() => setElevation(5)}
			onMouseLeave={() => setElevation(3)}
		>
			<Paper
				sx={{
					height: 195,
					width: 195,
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					padding: '1em',
				}}
				elevation={elevation}
			>
				<Box display="flex" flexDirection="column" alignItems="center">
						{/* <CompanyAvatar />  ICON  */}
						<Box sx={{
							// backgroundColor3 "#003057"
							position: 'relative',
							top: '5px',
							right: '15px'
						}}>
							<LogoImg src={logo}/>
						</Box>
						<Box textAlign="center" marginTop={1}>
								<Typography variant="h4">
										{props.TileHeader}
								</Typography>
								{/* <SelectField
										color="textSecondary"
										source="sector"
										choices={sectors}
								/> */}
						</Box>
				</Box>
					{/* <Box display="flex" justifyContent="space-around" width="100%">
						<Box display="flex" alignItems="center">
								<ContactsIcon color="disabled" sx={{ mr: 1 }} />
							
						</Box>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<DealIcon color="disabled" sx={{ mr: 1 }} />
							
						</Box>
					</Box> */}
				</Paper>
		</MuiLink>
	);
};

export default TileLink