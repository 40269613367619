import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Asterisk from "../../components/Asterisk";
import Note from "../../components/Note";
import StylizedLink from "../../components/StylizedLink";
import HasError from "../../components/HasError";
import { useOktaAuth } from '@okta/okta-react';
import AuthHeaderComponent from "../../components/AuthHeaderComponent";
import AuthContentComponent from "../../components/AuthContentComponent";
import { useNavigate } from "react-router-dom";
import Layout from "../../Pages/Layout";
import Heading from "../../components/Heading";
import ForgotPasswordForm from "./ForgotPasswordForm";





const BoxStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: 6,
  marginRight: 0,
  marginBottom: 45,
  marginLeft: 0,
}));


const ForgotPassword: React.FC = (props) => {
  //Style
  const [email, setEmail] = useState("");
  const { oktaAuth } = useOktaAuth();
  const [sessionToken, setSessionToken] = useState("");
  //Working

  const [password, setPassword] = useState("");
  const [username, setUserName] = useState("");
  const [errorValue, setErrorValue] = useState("");
  const [checked, setChecked] = useState(false);
  const navhook = useNavigate()

  const ButtonStyled = styled(Button)(({ theme }) => ({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: "bold",
    outline: 0,
    position: "relative",
    padding: "1rem 15px",
    border: "none",
    display: "inline-block",
    lineHeight: 1.5,
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.light,
    fontSize: "1.5rem",
    minWidth: 152,
    minHeight: 40,
    margin: 0,
    textAlign: "center",
    transition: "none",
    [theme.breakpoints.down(768)]: {
      minWidth: 80,
    },
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    },
    "&:disabled": {
      color: "#fff",
      backgroundColor: "#ddd",
    },
  }));



  const emailHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // call auth api to get reset link 
     
  };
  const checkHandler = () => {
    setChecked(!checked);
  };

  return (
    <>
     
        <Grid container  rowSpacing={1}>
          <Grid item spacing={1}>
            <Heading text={"Forgot your password?"} />
            <Typography variant="h5">
              {` Enter your email address below and we’ll send a link to reset your current password`}
            </Typography>
          </Grid>
      
          <Grid item>
            <ForgotPasswordForm />
          </Grid>
        </Grid>
    </>
  );
};

export default ForgotPassword;
