import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

type Props = {
  children?: React.ReactNode;
};

const AuthContentComponent: React.FC<Props> = (props) => {
  const AuthContent = styled(Box)(({ theme }) => ({
    display: "flex",
    paddingTop: theme.spacing(12),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    justifyContent: "center",
    [theme.breakpoints.up(1025)]: {
      flexGrow: 0,
      flexShrink: 0,
      minHeight: "100%",
      flexBasis: "75%",
      maxWidth: "75%",
    },
    [theme.breakpoints.between(641, 1025)]: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: "66.667%",
      maxWidth: "66.667%",
    },
    [theme.breakpoints.between(1, 641)]: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: "100%",
      maxWidth: "100%",
    },
    " > .auth-component": {
      flexGrow: 1,
      maxWidth: theme.spacing(60),
    },
  }));

  return <AuthContent component="section">{props.children}</AuthContent>;
};

export default AuthContentComponent;
