import React, { MouseEventHandler } from "react";
import { Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  MemoryRouter,
} from 'react-router-dom';
import common from '../config';

const config = common.config();


const StylizedLink: React.FC<{ text: string; value: string, target?: string, onClick?: MouseEventHandler, goBack?: boolean}> = (props) => {
  const LinkStyled = styled(Link)(({ theme }) => ({
    color: "#2678f4",
    "&:hover": {
      color: theme.palette.primary.main,
    },
    fontSize:"1.2rem",
  }));

  let navigate = useNavigate()
 // alert('notFired')
 let customProps;
if(props?.goBack){
   customProps = {
    onClick:()=>{
      if(props?.goBack){
        navigate(-1)
      }
    },
    target: props.target?? undefined,
    rel: "noopener noreferrer"
    
  }
} else {
 // navigate()
 let baseUrl = config.baseUrl
   customProps = {
     onClick:()=>{
       if(props?.goBack){
         navigate(-1)
      }
    },
    href: props.value,
    onclick: ()=> navigate(`${props.value}`),
    target: props.target?? undefined,
    rel: "noopener noreferrer"
  }
}


    console.log("🚀 ~ file: StylizedLink.tsx:34 ~ customProps:", {customProps})
    console.log("🚀 ~ file: StylizedLink.tsx:34 ~ value:", props.value)
  return (
    <LinkStyled
      underline="none"
      // component={RouterLink}
      {...customProps}
    >
      {props.text}
    </LinkStyled>
  );
};

export default StylizedLink;
